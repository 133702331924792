<template>
  <div class="insured-normal h-font-lg">
    <div class="base h-group">
        <div class="h-cell__content">
          <HYForm
            ref="viewHolderFormRef"
            fmCode="viewHolder"
            :config="viewInsuredConfig"
            :form="insuredInfo">
          </HYForm>
          <!-- <van-row>
              <van-col span="6"  class="h-text-color_gray-6">姓名</van-col>
              <van-col span="18">{{ insuredInfo.name }}</van-col>
          </van-row>
          <van-row>
              <van-col span="6" class="h-text-color_gray-6">证件号码</van-col>
              <van-col span="18">{{ insuredInfo.certificateNo }}</van-col>
          </van-row>
          <van-row>
              <van-col span="6"  class="h-text-color_gray-6">出生日期</van-col>
              <van-col span="18">{{ insuredInfo.birthday }}</van-col>
          </van-row>
          <van-row>
              <van-col span="6" class="h-text-color_gray-6">性别</van-col>
              <van-col span="18">{{ insuredInfo.gender === '1' ? '男' : '女' }}</van-col>
          </van-row> -->
      </div>
    </div>
    <div class="base h-group insured-edit" v-if="insuredConfig">
        <div class="h-cell__content">
          <HYForm
            ref="insuredFormRef"
            fmCode="insured"
            :config="insuredConfig"
            :form="formData">
          </HYForm>
      </div>
    </div>
    <div class="insured-normal_bnt">
      <div class="insured-normal_bnt--fixed" >
        <van-button type="primary" block round @click="submit">确认</van-button>
      </div>
    </div>
</div>
</template>
<script>
import { mapState } from "vuex";
import { Notify } from "vant";
import util from '@/utils/common.js'
import { cloneDeep } from "lodash";
import HSelect from '@/components/form-items/HSelect.vue'
import dayjs from 'dayjs'
import HYForm from "@/components/Form.vue";
import config from './config/insured'
export default {
  name: "insuredModel",
  components: { HSelect, HYForm },
  provide() {
    return {
      pageContext: this,
      pageForm:this.pageForm,
      pageLayout:this.pageLayout
    };
  },
  data() {
    return {
      formData: {
        name: '',
        certificateNo: ''
      },
      insuredConfig: null,
      viewInsuredConfig: {
        fmName: "投保人信息",
        fmCode: "holder",
        show: true,
        formItems: [
          {
            prop: "name",
            control: "HText",
            show: true,
            config: {
              label: "姓名",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "certificateNo",
            control: "HText",
            show: true,
            config: {
              label: "证件号码",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "birthday",
            control: "HText",
            show: true,
            config: {
              label: "出生日期",
              val: "",
              placeholder: "",
              disabled: false,
              required: true,
            }
          },
          {
            prop: "genderDictValue",
            control: "HText",
            show: true,
            config: {
              label: "性别",
              val: "",
              dictCode: "GENDER",
              placeholder: "",
              disabled: false,
              required: true,
            }
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    ...mapState("car", ["quote", "insuredInfo"]),
  },
  async created() {
    await this.initData();
    this.initLayoutBase();
    let insuredData = cloneDeep(this.quote.insured)
    insuredData.birthday = util.getBirthDay(insuredData.certificateNo)
    if (insuredData.gender === '1') {
      this.insuredInfo.genderDictValue = '男'
    } else {
      this.insuredInfo.genderDictValue = '女'
    }
    this.formData = insuredData
    this.$store.dispatch("car/setInsuredInfo", Object.assign(this.insuredInfo, insuredData))
  },
  mounted() {
  },
  methods: {
    dayjs,
    async initData() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    initLayoutBase() {
      this.insuredConfig = config
    },
    async submit () {
      const validate = await this.$refs.insuredFormRef.validateForm();
      if (!validate) {
        Notify({ type: "warning", message: "请完善信息" });
        return false
      }
      await this.$store.dispatch("car/setInsuredInfo", this.formData);//保存缓存
      this.$router.go(-1)
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
@import "@/assets/style/mixins/hairline.scss";
.insured-normal{
  padding: 0 8px;
  background-color: $background-color;
  height: 100vh;
  &_bnt{
    height: 64px;
    &--fixed{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 99;
      background-color: $white;
      padding: 10px;
      border-top: $background-color solid $border-radius-sm;
    }
  }
}
.insured-normal ::v-deep{
  .h-group{
    margin-bottom: 10px;
    border-radius: $border-radius-lg;
  }
  .base {
    position: relative;
    top: 10px;
    padding: 10px;
    background: #fff;
  }
  .h-cell__content{
      padding: 6px 8px;
      font-size: $font-size-sm;
      .van-row {
          padding:9px 0;
      }
  }
  .h-cell__title{
      font-weight: $font-weight-bold-lg;
      font-size: $font-size-md;
      span.icon {
          border-radius: $border-radius-sm;
          color: $white;
          padding: 3px;
          margin-right:6px;
      }
  }
  .h-cell{
      padding: 10px 8px;
  }
  .h-cell__value {
      color: #0066FF;
      font-size: 12px;
  }
  .van-field__label {
    color: #969799;
  }
  .insured-edit {
    .h-cell__content{
      padding: 6px 0px;
    }
  }
}
</style>
